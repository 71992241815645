.nivoSlider {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden
}

.nivoSlider img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none
}

.nivo-main-image {
    display: block!important;
    position: relative!important;
    width: 100%!important
}

.nivoSlider a.nivo-imageLink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    margin: 0;
    z-index: 6;
    display: none;
    background: white;
    filter: alpha(opacity=0);
    opacity: 0
}

.nivo-slice {
    display: block;
    position: absolute;
    z-index: 5;
    height: 100%;
    top: 0
}

.nivo-box {
    display: block;
    position: absolute;
    z-index: 5;
    overflow: hidden
}

.nivo-box img {
    display: block
}

.nivo-caption {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #000;
    color: #fff;
    width: 100%;
    z-index: 8;
    padding: 5px 10px;
    opacity: .8;
    overflow: hidden;
    display: none;
    -moz-opacity: .8;
    filter: alpha(opacity=8);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.nivo-caption p {
    padding: 5px;
    margin: 0
}

.nivo-html-caption {
    display: none
}

.nivo-directionNav a {
    position: absolute;
    top: 45%;
    z-index: 9;
    cursor: pointer
}

.nivo-prevNav {
    left: 0
}

.nivo-nextNav {
    right: 0
}

.nivo-controlNav {
    text-align: center;
    padding: 15px 0
}

.nivo-controlNav a {
    cursor: pointer
}

.nivo-controlNav a.active {
    font-weight: bold
}